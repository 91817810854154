import "./landing.scss"
import image from '../../images/undraw_progressive_app_m9ms.svg';
import React from "react"
import ScrollFade from "../common/scrollFade"
import PropTypes from "prop-types"
import { scrollIntoView } from "../common/header"

const Landing = ({ setIsContactOpen }) => {
  return (
    <div className={"Landing"} id={'home'}>
      <div className="container">
        <div className={'text-wrapper'}>
          <ScrollFade>
            <h1>Enterprise software development</h1>
          </ScrollFade>
          <div className={'text'}>
            <p>Leverage out five-years expertise <br/> to create a solid software foundation for your business</p>
            <div>
              <button onClick={(e) => scrollIntoView("our-work", e)}>See our work</button>
              <a className={'btn featured'} href={'https://calendly.com/slavosmn/15min'} target={'_blank'}>Talk to our experts</a>
            </div>
          </div>
        </div>
        <div className={'image-wrapper'}>
          <img src={image} alt={'app-ilustration'}/>
        </div>
      </div>
    </div>
  )
}

Landing.propTypes = {
  setIsContactOpen: PropTypes.func.isRequired,
}

export default Landing
