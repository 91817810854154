import React from "react"
import PropTypes from "prop-types"

const Area = ({title, description, active}) => {
  return (
    <div className={`Area ${active ? 'active': ''}`}>
      <h3>{title}</h3>
      <p>{description}</p>
      <strong>More info</strong>
    </div>
  )
}

Area.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

export default Area