import './areas.scss'
import useScrollPosition from '@react-hook/window-scroll'
import React from "react"
import Area from "./area"
import ScrollFade from "../common/scrollFade"
import { map } from "../../utils"
import prototyping from '../../images/undraw_prototyping_process_rswj.svg';
import scalable from '../../images/undraw_factory_dy0a.svg';
import development from '../../images/undraw_server_cluster_jwwq.svg';
import security from '../../images/undraw_two_factor_authentication_namy.svg';
import api from '../../images/undraw_programming_2svr.svg';
import CrossfadeImage from 'react-crossfade-image';

const START_SCROLL_OFFSET = 700;
const SCROLL_HEIGHT = 2000;
const WRAPPER_HEIGHT = 515;
const INDICATOR_HEIGHT = 100;

const Areas = props => {
  const scrollY = useScrollPosition(60); /*fps*/

  const areas = [
    {
      title: 'Rapid prototyping / PoC (proof-of-concept) creation',
      description: 'In our team we understand that time saving means cost saving. We are trying to achieve client focused approach, but never hesitate to say our opinion.',
      image: prototyping
    },
    {
      title: 'Scalable production systems',
      description: 'We have also expertise in a field of fully scalabel systems build upon scala and akka. ',
      image: scalable
    },
    {
      title: 'Web / Mobile app development',
      description: 'Different challenges require different solutions. We have experience with the full range of latest technologies.',
      image: development
    },
    {
      title: 'Security',
      description: 'Everything we create is baked by right security solutions.',
      image: security
    },
    {
      title: 'API Design',
      description: 'We use best practices for API design with emphasis on simplicity and sustainability.',
      image: api
    },
  ];

  const indicatorPosY = Math.min(Math.max(map(scrollY, START_SCROLL_OFFSET, START_SCROLL_OFFSET + SCROLL_HEIGHT, 0, WRAPPER_HEIGHT  - INDICATOR_HEIGHT), 0), WRAPPER_HEIGHT - INDICATOR_HEIGHT)
  const index = Math.min(Math.floor(Math.min(Math.max(map(scrollY, START_SCROLL_OFFSET, START_SCROLL_OFFSET + SCROLL_HEIGHT, 0, 1), 0), 1) / (1 / 5)), 4)
  return (
    <div className={'Areas'} style={{height: `${SCROLL_HEIGHT + 1000}px`}} id={'experience'}>
      <div className="container">
        <div className="left">
          <ScrollFade>
            <h2>Areas of expertise</h2>
          </ScrollFade>
          <div className="wrapper">
            <div className={"scroll-indicator"} style={{transform: `translateY(${indicatorPosY}px)`, height: INDICATOR_HEIGHT}}/>
            {areas.map((area, i) =>
              <ScrollFade>
                <Area
                  title={area.title}
                  description={area.description}
                  key={area.title}
                  active={index === i}
                />
                <img src={area.image} alt={'img'} className={'mobile-img'}/>
              </ScrollFade>)}
          </div>
        </div>
        <div className="right">
          <div className="wrapper">
            <CrossfadeImage src={areas[index].image} />
          </div>
        </div>
      </div>
    </div>
  )
}

Areas.propTypes = {

}

export default Areas
