import React, { useState } from "react"
import Layout from "../components/common/layout"
import Landing from "../components/landing/landing"
import Areas from "../components/areas/areas"
import Testimonials from "../components/testimonials/testimonials"
import Contact from "../components/contact/contact"

const IndexPage = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <Layout setIsContactOpen={setIsContactOpen}>
      <Landing setIsContactOpen={setIsContactOpen}/>
      <Areas setIsContactOpen={setIsContactOpen}/>
      <Testimonials setIsContactOpen={setIsContactOpen}/>
      <Contact isOpen={isContactOpen} setIsOpen={setIsContactOpen}/>
    </Layout>
  )
}
export default IndexPage
