import React, { useState } from "react"
import PropTypes from "prop-types"
import './contact.scss'
import image from '../../images/undraw_contact_us_15o2.svg';


const Contact = ({isOpen, setIsOpen}) => {
  return (
    <div className={'Contact'}>
      <div id="form" className={isOpen ? 'open' : ''}>
        <form  method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
          <input type="hidden" name="bot-field" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306" onClick={() => setIsOpen(false)}>
            <g>
              <g id="chevron-right">
                <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153   "/>
              </g>
            </g>
          </svg>
          <h2>We want to hear from you!</h2>
          <label htmlFor={'text'}>Your name</label>
          <input type={'text'} id={'text'} name="name"/>
          <label>Email address</label>
          <input type={'email'} name="email"/>
          <label>Your message</label>
          <textarea />
          <button type={'submit'} className={'featured'}>Send to Webinson</button>
        </form>
      </div>

      <div className="container">
        <div className="col">
          <h2>We are making ideas happen. Contact us.</h2>
          <p>Let's work together to create game-changing experiences that will take your brand to another level.</p>
          <a className={'btn featured'} href={'https://calendly.com/slavosmn/15min'} target={'_blank'}>Talk to our experts</a>
        </div>
        <img src={image} alt={'image'}/>
      </div>
    </div>
  )
}

Contact.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsContactOpen: PropTypes.func.isRequired,
}

export default Contact
