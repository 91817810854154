import React, { useRef } from "react"
import PropTypes from "prop-types"
import useScrollPosition from '@react-hook/window-scroll'
import './scrollFade.scss';
import { map } from "../../utils"

const MIN_OFFSET = 300;

const ScrollFade = ({ children }) => {
  const scrollY = useScrollPosition(60); /*fps*/
  const el = useRef();

  let opacity = 1;
  if (el && el.current) {
    const rect = el.current.getBoundingClientRect();
    const top = rect.top + rect.height / 2;
    if (top < MIN_OFFSET) {
      opacity = map(top, 0, MIN_OFFSET, 0, 1)
    }
    if (top > window.innerHeight - MIN_OFFSET) {
      opacity = map(top, window.innerHeight, window.innerHeight - MIN_OFFSET, 0, 1)
    }
    // console.log(top, window.innerHeight - MIN_OFFSET);
  }
  // console.log(scrollY);
  return (
    <div className={'ScrollFade'} ref={el} style={{opacity: opacity}}>
      { children }
    </div>
  )
}

ScrollFade.propTypes = {
  children: PropTypes.node.isRequired
}

export default ScrollFade