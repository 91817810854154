import React from "react"
import Area from "../areas/area"
import ScrollFade from "../common/scrollFade"
import image from '../../images/undraw_to_the_moon_v1mv.svg';
import './testimonials.scss';

const Testimonials = () => {
  const testimonials = [
    {
      title: 'Spotibo',
      description: 'Spotibo is SEO audit tool, which helps to find hidden SEO issues with negative impacts on large websites, enabling filtering data in a cloud without exporting data.\n Our goal was re-creation of existing app in newer tech stack.',
      image: 'https://i.imgur.com/JDfpEh0.png',
      link: 'https://www.spotibo.com',
      color: '#174069',
      stack: ['Spring stack', 'Microservices', 'Elasticsearch']
    },
    {
      title: 'Featherclick',
      description: 'Featherclick is a micropayment platform that allows people to transfer less than $1 amounts, to anyone in the world.\n Our mission was to bring fresh UI/UX ideas and make it real in web app.',
      image: 'https://i.imgur.com/O6l9hKK.png',
      color: '#464563',
      stack: ['React stack']
   },
    {
      title: 'Narative',
      description: 'For Narrative we have programmed a blazing-fast website together with an order form which briefs the client through the complete project.',
      image: 'https://i.imgur.com/FT17kqg.png',
      link: 'https://www.narative.sk',
      color: '#68A78C',
      stack: ['React', 'Gatsby', 'Netlify CMS']
    },
    {
      title: '33aplus',
      description: '33aplus is a czech company which teaches machines how to think, using unique algorithms.\n Our part of work was making backend solution for this task.',
      image: 'https://i.imgur.com/f8r4B9s.png',
      color: '#181818',
      stack: ['Akka', 'Lagom', 'Cassandra', 'Kubernetes']
    },
  ]
  return (
    <div className="Testimonials" id={'our-work'}>
      <div className="container">
        <div className="description">
          <img src={image} alt={'image'}/>

          <div className="text">
            <ScrollFade>
              <div>
                <h2>Hear from the customer</h2>
                {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae deserunt dicta, dolorem maxime perspiciatis suscipit voluptatibus. Animi, consectetur consequatur et illum minima nesciunt officia perspiciatis sed! Est labore nam nostrum.</p>*/}
              </div>
            </ScrollFade>
          </div>
        </div>
        <div className="content">
          {testimonials.map((testimonial, i) => (
            <div className={'testimonial'} style={{backgroundImage: `url(${testimonial.image})`, backgroundColor: testimonial.color}}>
              <div>
                <a href={testimonial.link} style={{color: 'white'}}><h3>{testimonial.title}</h3></a>
                <p>{testimonial.description}</p>
                <hr/>
                <i>{testimonial.stack.join(' | ')}</i>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

  )
}

export default Testimonials
